import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
              {children}
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
