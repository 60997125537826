import React from "react";
import { Link } from "gatsby";
import { useState } from "react";
import { Transition } from "@headlessui/react";
import ImageDumblefyLogo from "../../static/logo.svg";
import ImageDumblefyLogoMobileMenu from "../images/logo-mobile-menu.png";

const Header = () => {
  const [ mobileMenuIsOpen, setMobileMenuIsOpen ] = useState(false);
  return (
    <header className="relative">
      {/* <div className="bg-gray-900 pt-6"> */}
      <div className="bg-gray-900 py-6">
        <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              {/* Logo */}
              <Link to="/">
                <span className="sr-only">Dumblefy</span>
                {/* https://tailwindui.com/img/logos/workflow-mark-teal-200-cyan-400.svg" */}
                <img className="h-8 w-auto sm:h-10" src={ImageDumblefyLogo} alt="Dumblefy" />
              </Link>
              {/* Mobile Menu - Hanburger */}
              <div className="-mr-2 flex items-center md:hidden">
                <button type="button" onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)} className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white" id="main-menu" aria-haspopup="true">
                  <span className="sr-only">Open menu</span>
                  {/* Heroicon name: menu */}
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="hidden space-x-8 md:flex md:ml-10">
              <Link to="/" className="text-base font-medium text-white hover:text-gray-300">
                Home
              </Link>
              <Link to="/about/" className="text-base font-medium text-white hover:text-gray-300">
                About
              </Link>
              <Link to="/contact/" className="text-base font-medium text-white hover:text-gray-300">
                Contact
              </Link>
            </div>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-6">
            {/* <a href="#" className="text-base font-medium text-white hover:text-gray-300">
              Log in
            </a> */}
            <a href="https://support.dumblefy.com" target="_blank" rel="noopener noreferrer" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700">
              Help Center
            </a>
          </div>
        </nav>
      </div>
      {/*
        Mobile menu, show/hide based on menu open state.

        Entering: "duration-150 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      */}
      <Transition
        show={mobileMenuIsOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div ref={ref} className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  {/* https://tailwindui.com/img/logos/workflow-mark-teal-500-cyan-600.svg" */}
                  <img className="h-8 w-auto" src={ImageDumblefyLogoMobileMenu} alt="Dumblefy" />
                </div>
                <div className="-mr-2">
                  <button type="button" onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                    <span className="sr-only">Close menu</span>
                    {/* Heroicon name: x */}
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="pt-5 pb-6" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                <div className="px-2 space-y-1" role="none">
                  <Link to="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50" role="menuitem">Home</Link>
                  <Link to="/about/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50" role="menuitem">About</Link>
                  <Link to="/contact/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50" role="menuitem">Contact</Link>
                </div>
                <div role="none" className="mt-6 px-5">
                  <Link to="/contact/" className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700">
                    Get in touch
                  </Link>
                </div>
                <div role="none" className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">Support? <a href="https://support.dumblefy.com" target="_blank" rel="noopener noreferrer" className="text-gray-900 hover:underline">Help Center</a></p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </header>
  )
}

export default Header
